import React from "react";
import PropTypes from "prop-types";
import Helmet from 'react-helmet'
import { useStaticQuery, Link, graphql } from "gatsby";
import styled from "styled-components";
import Img from "gatsby-image";
import logoImg from "../images/logo-icon.png";
import { StyledLogoImg } from "../components/Logo";

import debounceFn from 'debounce-fn'
import matchSorter from 'match-sorter'
// import theme from '../../config/theme'


import { Layout, Article, Wrapper, Button, SectionTitle, Header } from "../components";
import {StyledH3, SecondaryH2 } from '../components/StyledElements';
import config from '../../config'

const Content = styled.div`
  grid-column: 1/4;
  box-shadow: 0 4px 120px rgba(0, 0, 0, 0.1);
  border-radius: 1rem;
  padding: 3rem 3rem;
  @media (max-width: ${props => props.theme.breakpoints.tablet}) {
    padding: 3rem 2rem;
  }
  @media (max-width: ${props => props.theme.breakpoints.phone}) {
    padding: 2rem 1.5rem;
  }
  overflow: hidden;
`;

const Hero = styled.div`
  grid-column: 2;
  padding: 3rem 2rem 6rem 2rem;
  text-shadow: 0 12px 30px rgba(0, 0, 0, 0.15);
  color: ${props => props.theme.colors.grey.dark};

  @media (max-width: ${props => props.theme.breakpoints.phone}) {
    padding: 2rem 1rem 4rem 1rem;
  }

  p {
    font-size: 1.68rem;
    margin-top: -1rem;
    @media (max-width: ${props => props.theme.breakpoints.tablet}) {
      font-size: 1.45rem;
    }
    @media (max-width: ${props => props.theme.breakpoints.phone}) {
      font-size: 1.25rem;
    }
  }
`;

const Input = styled.input`
width: 100%;
font-size: 3em;
height: 1em;
background-color: white;
border: 3px solid ${props => props.theme.colors.primary};
color: ${props => props.theme.colors.primary};
padding: 0.25em;
`;

  // <Layout>
  //   <Wrapper>
  //     <Helmet title={`Categories | ${config.siteTitle}`} />
  //     <Header>
  //       <Link to="/">{config.siteTitle}</Link>
  //     </Header>
  //     <Content>
  //       <SectionTitle>Categories</SectionTitle>
  //       {group.map(category => (
  //         <Title key={category.fieldValue}>
  //           <Link to={`/categories/${kebabCase(category.fieldValue)}`}>{category.fieldValue}</Link> (
  //           {category.totalCount})
  //         </Title>
  //       ))}
  //     </Content>
  //   </Wrapper>
  // </Layout>

  // materialShadow(level) {
  //   switch (level) {
  //     case 0:
  //       return 'box-shadow: none;';
  //     case 1:
  //       return 'box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)';
  //     case 2:
  //       return 'box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23)';
  //     case 3:
  //       return 'box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23)';
  //     case 4:
  //       return 'box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22)';
  //     default:
  //       return 'box-shadow: 0 19px 38px rgba(0,0,0,0.30), 0 15px 12px rgba(0,0,0,0.22)';
  //   }
  // },

  const Card = styled.div`
  background: white;
  box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
  border-radius: 5px;
  padding: 30px;
  transition: all ${props => props.theme.transitions.normal};
  &:hover {
    box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
  }`;

function BlogPostCard({blogpost}) {
  const {slug, title, shortDescription, keywords, banner, subTitle} = blogpost
  return (
    <Link to={slug} css={{color: 'initial', margin: 20, width: 320}}>
      <Card
      >
        <h3 css={{marginTop: 0}}>{subTitle}</h3>
        <Img fluid={banner.childImageSharp.fluid} alt={keywords.join(', ')} />
        <div css={{margin: '16px 0 0 0'}}>{shortDescription}</div>
      </Card>
    </Link>
  )
}

function useDebFn(cb, opts) {
  return React.useCallback(debounceFn(cb, opts), [])
}

const SearchPage = ({
  data: {
    allMdx: { nodes: posts }
  }
}) => {
  
  // this will be the same every time and because this re-renders on every
  // keystroke I'm pretty sure useMemo is appropriate here.
  const blogposts = React.useMemo(() => {
    // console.log("POSTS:", posts)
    return posts.map(post => ({
      ...post.fields,
      ...post.frontmatter,
      excerpt: post.excerpt,
    }))
  }, [posts])

  const [search, setSearch] = React.useState('')
  const searchInputRef = React.useRef()
  const filteredBlogPosts = matchSorter(blogposts, search, {
    keys: [
      'title',
      'categories',
      'keywords',
      {key: 'description', threshold: matchSorter.rankings.CONTAINS},
      {key: 'excerpt', threshold: matchSorter.rankings.CONTAINS},
    ],
  })

  return (
    <Layout>
      <Wrapper>
      <Helmet title={`Search | ${config.siteTitle}`} />
        <Hero>
        <Link to={'/'} > 
          <StyledLogoImg src={logoImg} alt={"7 good gifts logo"} height={"25%"} width={"25%"}/>
        </Link>
          <SecondaryH2>Find Gifts</SecondaryH2>
          <label
            htmlFor="search-input"
            css={{margin: '0 10px 0 0', display: 'block'}}
          >
            Search Gift Ideas
          </label>
          <Input
            id="search-input"
            ref={searchInputRef}
            onChange={useDebFn(() => setSearch(searchInputRef.current.value), {
              wait: 200,
            })}
            type="search"
            autoFocus
          />
        <small>
          {`If you can't find what you're looking for with this, try `}
          <a href="https://www.google.com/search?q=site%3A7goodgifts.com+gift+ideas">
            using Google
          </a>
          {'.'}
        </small>
        </Hero>
        <Content>
        {/* <div css={{marginTop: 50}}>
          
        </div> */}
        <div
          css={{
            marginTop: 20,
            display: 'flex',
            flexWrap: 'wrap',
            justifyContent: 'center',
          }}
        >
          {filteredBlogPosts.map(blogpost => {
            console.log("blogpost:", blogpost)
            return (
            <BlogPostCard key={blogpost.id} blogpost={blogpost} />
          )})}
        </div>
        </Content>
      </Wrapper>
    </Layout>
  );
}

export default SearchPage;


SearchPage.propTypes = {
  data: PropTypes.shape({
    allMdx: PropTypes.shape({
      nodes: PropTypes.array.isRequired
    })
  }).isRequired
};

export const SearchQuery = graphql`
  query SearchQuery {
    allMdx(sort: { fields: [frontmatter___date], order: DESC }) {
      nodes {
        excerpt(pruneLength: 190)
        timeToRead
        id
        fields {
          slug
        }
        frontmatter {
          title
          subTitle
          date(formatString: "MM/DD/YYYY")
          categories
          primaryColor
          keywords
          description
          shortDescription
          banner {
            ...bannerImage640
          }
        }
      }
    }
  }
`;


